import Swiper from "swiper";

var defaultSlider = new Swiper('.js-default-slider', {
    slidesPerView: 3,
    spaceBetween: 42,
    grabCursor: true,
    navigation: {
        nextEl: '.jsSwiper-default-next',
        prevEl: '.jsSwiper-default-prev',
    },
    breakpoints: {
        932: {
            slidesPerView: 2
        },
        700: {
            slidesPerView: 1,
            spaceBetween: 20,
        }
    }
});

var productSlider = new Swiper('.js-product-slider', {
    slidesPerView: 1,
    spaceBetween: 47,
    loop: true,
    grabCursor: true,
    navigation: {
        nextEl: '.jsSwiper-product-next',
        prevEl: '.jsSwiper-product-prev',
    }
});
